@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}
.evento{
	.fecha{
		.grande,.peque{
			// display:block;
			// width:55px;
		}
	}
}
.upper{
  text-transform:uppercase;
}
.noticia-enlace{
  img{
    width:100%;
  }
}
em.fecha{
	text-transform:capitalize;
}
.pagination{
	.disabled{
		span{
			border-color:white;
		}
	}
}
.media-carousel {
	.carousel-control{
		.left{
			left: -12px;
		}
		.right{
			right: -12px !important;
		}
	  background-image: none;
	  background: none repeat scroll 0 0 #222222;
	  border: 4px solid #FFFFFF;
	  border-radius: 23px 23px 23px 23px;
	  height: 40px;
	  width: 40px;
	  margin-top: 80px;
	}
}
li.dropdown{
	list-style:none;
}
.igualdad{
	display:block;
	width:71px;
	height:100px;
	position:absolute;
	top:20px;
	right:20px;
	background:transparent url('../images/igualdad.png') no-repeat;
}
.oculto{
	display:none;
}

.navbar-nav {
  float: left;
}
