@charset "utf-8";
/* CSS Document */
.span1, .span2, .span3, .span4, .span5, .span6, .span7, .span8, .span9, .span10, .span11, .span12{
	/*background-color:#FF9;
	opacity:0.6;*/
}
body{
	/*background:url("../images/grid_12cols.png") center no-repeat;*/
}

img{
	border:none;
}
.txt_center{
    text-align:center;
}
.dp_in{
    display:inline;
	margin-right:30px;
}
.cuadro_borde_gris {
    border: 1px solid #CCCCCC;
    text-align: center;
    height: 25px;
    width: 25px;
    margin: -3px auto auto;
}

.marca_div {
    border: 1px solid #000000;
    background-color: #CCCCCC;
}

.float_left {
    float: left;
}

.float_right {
    float: right;
}

.clear_both {
    clear: both;
}

.margin_0 {
    margin: 0px;
}

.margin_auto {
    margin: auto;
}

.margin_top_0 {
    margin-top: 0px;
}

.margin_top_10 {
    margin-top: 10px ! important;
}

.padding_10 {
    padding: 10px;
}
.padding_top_10 {
    padding-top: 10px;
}
.padding_bottom_10 {
    padding-bottom: 10px;
}
.padding_left_15{
	padding-left:15px;
}
.texto_blanco{
	color:#FFF;
}


.margin_10{
	margin:10px;
}
.margin_top_20 {
    margin-top: 20px ! important;
}
.margin_bottom_10 {
    margin-bottom: 10px;
}

.margin_bottom_20 {
    margin-bottom: 20px;
}

.margin_bottom_40 {
    margin-bottom: 40px;
}

.margin_top_40 {
    margin-top: 40px;
}

.margin_left_20 {
    margin-left: 20px;
}
.margin_right_10{
	margin-right:10px;
}

.margin_right_20 {
    margin-right: 20px;
}
.margin_right_30 {
    margin-right: 30px;
}

.margin_top_100 {
    margin-top: 100px;
}

.margin_top_menos10 {
    margin-top: -10px;
}

.margin_top_menos20 {
    margin-top: -20px;
}
.margin_top_menos100{
	margin-top:-100px;
}

.margin_right_menos20 {
    margin-right: -20px;
}

.strong {
    font-weight: bold ! important;
}

.overflow_hidden {
    overflow: hidden;
}

.width_960 {
    width: 960px;
}
.width_700 {
    width: 700px;
}

.width_500 {
    width: 500px;
}

.width_400 {
    width: 400px;
}

.width_200 {
    width: 200px;
}

.width_150 {
    width: 150px;
}

.width_50 {
    width: 50px;
}

.vertical_align_middle {
    vertical-align: middle;
}
.vertical_align_bottom{
	vertical-align:bottom;
}
.text_align_center {
    text-align: center;
}
.text_align_right {
    text-align: right !important;
}

#menu {
    padding: 0px;
    list-style: none outside none;
    color: #333;
    font-size: 1em;
    font-weight: bold;
    overflow: hidden;
}

#menu li {
    padding: 0px;
    float: left;
    margin-right: 10px;
}

.lista_horizontal {
    padding: 0px;
    list-style: none outside none;
	overflow:hidden;
}

.lista_horizontal li {
    padding: 0px;
    float: left;
    margin-right: 10px;
    margin-bottom:10px;
}

.centro_absoluto {
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}


/************PINTEREST ************/


#pinterest_columns {
	-webkit-column-count: 3;
	-webkit-column-gap: 10px;
	-webkit-column-fill: auto;
	-moz-column-count: 3;
	-moz-column-gap: 10px;
	-moz-column-fill: auto;
	column-count: 3;
	column-gap: 15px;
	column-fill: auto;
}

.pin {
	display: inline-block;
	background: #FEFEFE;
	-webkit-column-break-inside: avoid;
	-moz-column-break-inside: avoid;
	column-break-inside: avoid;
	border: 2px solid #FAFAFA;
	box-shadow: 0 1px 2px rgba(34, 25, 25, 0.4);
	margin: 0 2px 15px;
	/*padding: 15px;
	padding-bottom: 5px;*/
	padding: 0 15px;
	background: -webkit-linear-gradient(45deg, #FFF, #F9F9F9);
	opacity: 1;
	-webkit-transition: all .2s ease;
	-moz-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease;
}

.pin img {
	width: 100%;
}

.pin p {
	font: 12px/18px Arial, sans-serif;
	color: #333;
	margin: 0;
}


.row:hover .pin:not(:hover) {
	/*opacity: 0.4;*/
}
/**************************FIN PINTEREST*************/


/*********************3D BUTTON********************/
.button_3d {
	display: inline-block;
	text-decoration: none;
	color: #fff;
	font-weight: bold;
	background-color: #538fbe;
	padding: 20px 70px;
	font-size: 24px;
	border: 1px solid #2d6898;
	background-image: linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);
	background-image: -o-linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);
	background-image: -moz-linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);
	background-image: -webkit-linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);
	background-image: -ms-linear-gradient(bottom, rgb(73,132,180) 0%, rgb(97,155,203) 100%);

	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		color-stop(0, rgb(73,132,180)),
		color-stop(1, rgb(97,155,203))
	);
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	text-shadow: 0px -1px 0px rgba(0,0,0,.5);
	-webkit-box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
	-moz-box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
	box-shadow: 0px 6px 0px #2b638f, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
	-webkit-transition: all .1s ease-in-out;
	-moz-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	-webkit-transform: rotateX(20deg);
}

.button_3d:hover {
	background-image: linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
	background-image: -o-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
	background-image: -moz-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
	background-image: -webkit-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);
	background-image: -ms-linear-gradient(bottom, rgb(79,142,191) 0%, rgb(102,166,214) 100%);

	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		color-stop(0, rgb(79,142,191)),
		color-stop(1, rgb(102,166,214))
	);
}

.button_3d:active {
-webkit-box-shadow: 0px 2px 0px #2b638f, 0px 1px 6px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
-moz-box-shadow: 0px 2px 0px #2b638f, 0px 1px 6px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
box-shadow: 0px 2px 0px #2b638f, 0px 1px 6px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
	background-image: linear-gradient(bottom, rgb(88,154,204) 0%, rgb(90,150,199) 100%);
	background-image: -o-linear-gradient(bottom, rgb(88,154,204) 0%, rgb(90,150,199) 100%);
	background-image: -moz-linear-gradient(bottom, rgb(88,154,204) 0%, rgb(90,150,199) 100%);
	background-image: -webkit-linear-gradient(bottom, rgb(88,154,204) 0%, rgb(90,150,199) 100%);
	background-image: -ms-linear-gradient(bottom, rgb(88,154,204) 0%, rgb(90,150,199) 100%);

	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		color-stop(0, rgb(88,154,204)),
		color-stop(1, rgb(90,150,199))
	);
    -webkit-transform: translate(0, 4px) rotateX(20deg);
    -moz-transform: translate(0, 4px);
    transform: translate(0, 4px);
}
/*********************fin 3D BUTTON********************/

/**************EFECTO 3D**********************/
.text-shadow {
    text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #C9C9C9, 0 3px 0 #BBBBBB, 0 4px 0 #B9B9B9, 0 5px 0 #AAAAAA, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}
.box-shadow {

    border-radius: 5px;
    box-shadow: 0 6px 0 #EA7400, 0 3px 15px rgba(0, 0, 0, 0.4), 0 1px 0 rgba(255, 255, 255, 0.3) inset, 0 0 3px rgba(255, 255, 255, 0.5) inset;
    transition: all 0.2s ease-in-out 0s;
}
/********************fin efecto 3d********************/

/************STICKY FOOTER*************/
/*html {
    position: relative;
    min-height: 100%;
}
body {
    margin: 0 0 70px;
}
footer {
	background-color:#333;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 70px;
    width: 100%;
}*/
/*****************************/

/*****************************************************************************************************************************
******************************************************************************************************************************
******************************************************************************************************************************
EFECTO DE SOMBRA

EXIGE QUE LA CAPA DONDE ESTAN TENGA:
position:relative;
z-index:-2;

Ejemplos: http://www.paulund.co.uk/playground/demo/css_box_shadow/
******************************************************************************************************************************
******************************************************************************************************************************
******************************************************************************************************************************/

/*==================================================
 * Effect 1
 * ===============================================*/
.sombra_simple{
	-webkit-box-shadow: 0 10px 6px -6px #777;
	   -moz-box-shadow: 0 10px 6px -6px #777;
	        box-shadow: 0 10px 6px -6px #777;
}

/*==================================================
 * Effect 2
 * ===============================================*/
.sombra_doble
{
  position: relative;
}
.sombra_doble:before, .sombra_doble:after
{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.sombra_doble:after
{
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

/*==================================================
 * Effect 3
 * ===============================================*/
.sombra_izquierda
{
  position: relative;
}
.sombra_izquierda:before
{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

/*==================================================
 * Effect 4
 * ===============================================*/
.sombra_derecha
{
  position: relative;
}
.sombra_derecha:after
{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  right: 10px;
  left: auto;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}

/*==================================================
 * Effect 5
 * ===============================================*/
.sombra_doble_grande
{
  position: relative;
}
.sombra_doble_grande:before, .sombra_doble_grande:after
{
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 25px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width:300px;
  background: #777;
  -webkit-box-shadow: 0 35px 20px #777;
  -moz-box-shadow: 0 35px 20px #777;
  box-shadow: 0 35px 20px #777;
  -webkit-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  -o-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  transform: rotate(-8deg);
}
.sombra_doble_grande:after
{
  -webkit-transform: rotate(8deg);
  -moz-transform: rotate(8deg);
  -o-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
  right: 10px;
  left: auto;
}

/*==================================================
 * Effect 6
 * ===============================================*/
.sombra_inferior
{
  	position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.sombra_inferior:before, .sombra_inferior:after
{
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.sombra_inferior:after
{
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}

/*==================================================
 * Effect 7
 * ===============================================*/
.sombra_tarjeta
{
  	position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.sombra_tarjeta:before, .sombra_tarjeta:after
{
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:0;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.sombra_tarjeta:after
{
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}

/*==================================================
 * Effect 8
 * ===============================================*/
.sombra_tarjeta_lateral
{
  	position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.sombra_tarjeta_lateral:before, .sombra_tarjeta_lateral:after
{
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:10px;
    bottom:10px;
    left:0;
    right:0;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.sombra_tarjeta_lateral:after
{
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}
/*******************************************FIN EFECTOS SOMBRA***************************************/


/*!
 * Bootstrap-select v1.6.3 (http://silviomoreto.github.io/bootstrap-select/)
 *
 * Copyright 2013-2014 bootstrap-select
 * Licensed under MIT (https://github.com/silviomoreto/bootstrap-select/blob/master/LICENSE)
 */

.bootstrap-select {
  /*width: 220px\9; IE8 and below*/
  width: 220px \0;
  /*IE9 and below*/
}
.bootstrap-select > .btn {
  width: 100%;
  padding-right: 25px;
}
.error .bootstrap-select .btn {
  border: 1px solid #b94a48;
}
.control-group.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48;
}
.bootstrap-select.fit-width {
  width: auto !important;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}
.bootstrap-select .btn:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}
.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none;
}
.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}
.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}
.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row-fluid .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}
.form-search .bootstrap-select.btn-group,
.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  margin-bottom: 0;
}
.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0;
}
.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%;
}
.input-append .bootstrap-select.btn-group {
  margin-left: -1px;
}
.input-prepend .bootstrap-select.btn-group {
  margin-right: -1px;
}
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important;
}
.bootstrap-select.btn-group .btn .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
}
.bootstrap-select.btn-group .btn .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle;
}
.bootstrap-select.btn-group[class*="col-"] .btn {
  width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  z-index: 1035;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.bootstrap-select.btn-group .dropdown-menu li {
  position: relative;
}
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) a:hover small,
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) a:focus small,
.bootstrap-select.btn-group .dropdown-menu li.active:not(.disabled) a small {
  color: #64b1d8;
  color: rgba(100, 177, 216, 0.4);
}
.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed;
}
.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
}
.bootstrap-select.btn-group .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em;
}
.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none;
}
.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block;
}
.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em;
}
.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
}
.bootstrap-select.btn-group.fit-width .btn .filter-option {
  position: static;
}
.bootstrap-select.btn-group.fit-width .btn .caret {
  position: static;
  top: auto;
  margin-top: -1px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}
.bootstrap-select.show-menu-arrow.open > .btn {
  z-index: 1035 + 1;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom-width: 7px;
  border-bottom-style: solid;
  border-bottom-color: #cccccc;
  border-bottom-color: rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-bottom: 0;
  border-top-width: 7px;
  border-top-style: solid;
  border-top-color: #cccccc;
  border-top-color: rgba(204, 204, 204, 0.2);
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block;
}
.bs-searchbox,
.bs-actionsbox {
  padding: 4px 8px;
}
.bs-actionsbox {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.bs-actionsbox .btn-group button {
  width: 50%;
}
.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px;
}
.bs-searchbox input.form-control {
  margin-bottom: 0;
  width: 100%;
}
.mobile-device {
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  height: 100% !important;
  opacity: 0;
}
/*# sourceMappingURL=bootstrap-select.css.map */

/*****************************MODIFICACIONES SOBRE EL BOOTSTRAP***********************************/

body {
  font-weight: 300;
  letter-spacing: .25px;
  font-size: 15px;
}


.navbar-default {
    background-color: transparent;
    border-color: transparent;
}
.navbar-default .navbar-nav > li > a {
    color: #ffffff;
	border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    background-color: transparent;
    border-bottom: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
    color: #ffffff;
}

.navbar-nav > li > a {
    padding-bottom: 5px;
    padding-top: 5px;
}

.stylish-input-group .input-group-addon{
    background: white !important;
}
.stylish-input-group .form-control{
	border-bottom-left-radius: 5px;
    border-top: 1px solid #ffffff;
    border-top-left-radius: 5px;
}
.stylish-input-group button{
    border:0;
    background:transparent;
}

.navbar {
    margin-bottom: 5px;
}




.navbar-nav{
	float:left;
}
@media (min-width: 768px) {
	.navbar-nav{
		float:left;
	}
	.navbar-nav li{
		/*text-align:center*/
	}
}
.carousel-inner > .item{
	/*height: 480px;*/
	background-repeat:no-repeat;
	background-position:center center;
	background-size:cover;
}
.carousel-control.left {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
.carousel-control.left:hover {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
}
.carousel-control.right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
}
.carousel-control.right:hover{
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
}

.navbar-brand {
    padding: 5px 15px 15px;
}

h1, .h1, h2, .h2, h3, .h3 {
    font-weight: 100;
}

.thumbnails li{
	height: 140px;
}

.carousel-caption {
    background-color: rgba(204, 204, 204, 0.53);
    bottom: 0;
    color: #4e4e4e;
    left: 15%;
    padding-bottom: 10px;
    padding-top: 10px;
    position: absolute;
    right: 15%;
    text-align: center;
    text-shadow: none;
    z-index: 10;
}

.carousel-caption h1 {font-weight: 600; color: #183980;}
/*EFECTO FADE EN CAROUSEL*/
.carousel.carousel-fade .item {
	-webkit-transition: opacity 0.5s ease-in-out;
	-moz-transition: opacity 0.5s ease-in-out;
	-ms-transition: opacity 0.5s ease-in-out;
	-o-transition: opacity 0.5s ease-in-out;
	transition: opacity 0.5s ease-in-out;
	opacity:0;
}
.carousel.carousel-fade .active.item {
	opacity:1;
}
.carousel.carousel-fade .active.left,.carousel.carousel-fade .active.right {
	left: 0;
	z-index: 2;
	opacity: 0;
	filter: alpha(opacity=0);}

.carousel.carousel-fade .next,.carousel.carousel-fade .prev {
	left: 0;
	z-index: 1;}

.carousel.carousel-fade .carousel-control {
	z-index: 3;
}
/*FIN EFECTO FADE EN CAROUSEL*/

.form-control {
	border: 1px solid #C2C2C2;
    border-radius: 0;
	margin-bottom: 5px;
	box-shadow: none;
}
.input-group-addon {
    border: none;
}


.file_input{
	position:absolute;
	z-index:2;
	top:0;
	left:0;
	filter: alpha(opacity=0);
	-ms-filter:'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
	opacity:0;
	background-color:transparent;
	color:transparent;
}
a {
    color: #183980;
}
a:hover {
    color: #183980;
	text-decoration:none;
}

.navbar-brand {
    height: auto;
}
@media (min-width: 768px) {
	.navbar-brand {
		height: 50px;
	}
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent;
}

.navbar-toggle {
    border: 0 solid #183980;
	border-radius:0px;
	}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
}
.navbar-toggle .icon-bar {
    height: 3px;
    margin-bottom: 7px;
    width: 30px;
}
/******change breakpoint*********/
@media (max-width: 1200px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
    /* since 3.1.0 */
    .navbar-collapse.collapse.in {
        display: block!important;
    }
    .collapsing {
        overflow: hidden!important;
    }
}

.list-group-item {
    border: 0 ;
	}

/*****************************FIN MODIFICACIONES SOBRE EL BOOTSTRAP********************************/

@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,700);
body {
font-family: 'Source Sans Pro', sans-serif;
  background:#f0f0f0;
}

.cabecera-amalgama{
	background-color:#183980;
	/*min-height:110px;*/
}
.cabecera-amalgama .navbar{
	margin-bottom: 8px;
    margin-top: 10px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.1;
  color: inherit;
  letter-spacing: .5px;
}


/****************************MEGAMENU**************************************/

.navbar-nav>li>.dropdown-menu {
    margin-top:20px;
    border-top-left-radius:4px;
    border-top-right-radius:4px;
}
.navbar-default .navbar-nav>li>a {
    /*width:200px;*/
	text-transform: uppercase;
    font-weight:300;
    letter-spacing: .5px;
}
.mega-dropdown {
  position: static !important;
  /*width:100%;*/
}
.mega-dropdown-menu {
    padding: 20px 10px;
    width: 60%;
    box-shadow: none;
    -webkit-box-shadow: none;
	background-color:#183980;
	color:#ffffff;
	border: 0;
}
.mega-dropdown-menu  h3{
	font-size: 18px;
	margin-bottom: 20px;
	}


.navbar-nav > li > .dropdown-menu {
    margin-top: 0px;
}
.mega-dropdown-menu .submenu > li {
	border-bottom: 1px solid #ffffff;
    width: 70%;
}
/*
.mega-dropdown-menu:before {
    content: "";
    border-bottom: 15px solid #fff;
    border-right: 17px solid transparent;
    border-left: 17px solid transparent;
    position: absolute;
    top: -15px;
    left: 285px;
    z-index: 10;
}
.mega-dropdown-menu:after {
    content: "";
    border-bottom: 17px solid #ccc;
    border-right: 19px solid transparent;
    border-left: 19px solid transparent;
    position: absolute;
    top: -17px;
    left: 283px;
    z-index: 8;
}*/
.mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0;
}
.mega-dropdown-menu ul {
    padding-left: 0;
}
.mega-dropdown-menu li {
  list-style: none;
}
.mega-dropdown-menu  li  a {
  display: block;
  padding: 7px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #ffffff;
  white-space: normal;
}
.mega-dropdown-menu  li  a:hover, .mega-dropdown-menu li a:focus {
  text-decoration: none;
  color: #444;
  background-color: #f5f5f5;
}
.mega-dropdown-menu .dropdown-header {
  color: #428bca;
  font-size: 18px;
  font-weight:bold;
}
.mega-dropdown-menu form {
    margin:3px 0px;
}
.mega-dropdown-menu .form-group {
    margin-bottom: 3px;
}
.mega-dropdown-menu .banner {
	text-align:center;
	margin-top: 10px;
}
.mega-dropdown-menu .banner img{
	max-height:120px;
}
.mega-dropdown-menu .divider-vertical{
	border-right:0;
}
@media (min-width: 768px) {
	.mega-dropdown-menu .divider-vertical{
	border-right:1px solid white;
	}
}


/********************************************FIN MEGAMENU****************************************/


.normal-dropdown-menu {
    padding: 20px 10px;
    min-width: 200px;
    box-shadow: none;
    -webkit-box-shadow: none;
	color:#ffffff;
	border: 0;
	/*fix en menu movil*/
	background-color:#183980 !important;
	position: relative !important;
    width: 100% !important;
}
@media (min-width: 768px) {
	.normal-dropdown-menu {
		position: absolute !important;
	}
}

.normal-dropdown-menu  a{
	display: block;
  padding: 7px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #ffffff;
  white-space: normal;
  font-size:16px;
	}
.normal-dropdown-menu  a:hover, .normal-dropdown-menu a:focus {
  text-decoration: none;
  color: #444;
  background-color: #f5f5f5;
}
.normal-dropdown-menu  .banner{
	text-align:center;
	margin-top: 10px;

	}
.normal-dropdown-menu  .banner img{
	max-height:160px;
	}


body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color:#ffffff;
  color:#666666;
}


.display-table{
	display: inline;
}
@media (min-width: 768px) {
	.display-table{
		display: table;
	}
}

.cuadro-intro > h3, .cuadro-intro > p, .cuadro-intro > ul{
	padding-left:20px;
	padding-right:20px;
 }
.cuadro-intro > h3{
 	padding-bottom: 10px;
}
.border-bottom-rojo{
	border-bottom: 1px solid #d10000;
}
.border-bottom-verde{
	border-bottom: 1px solid #1E7A00;
}
.border-bottom-azul{
	border-bottom: 1px solid #0058A0;
}

footer {
    background-color: #505050;
	padding:20px;
	color:#ffffff;
	margin-top: 40px;
}
footer  a{
	color:#ffffff;
	}
footer .fa-stack{
	color:#505050;
	}
footer .titulo {
	font-size:24px;
	text-transform:uppercase;
}
footer .titulo  img{
	margin-left:10px;
}
footer .email-signup  {
	margin-bottom: 30px;
	}
footer .email-signup input {
	background: transparent;
    border-color: #ffffff;
    border-style: none none solid;
    border-width: 0 0 1px;
    color: #fff;
    font-size: 4em;
    font-weight: lighter;
    letter-spacing: -1px;
    width: 100%;
}

footer .email-signup  label {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 5px;
}
footer hr{
	margin-top:30px;
	margin-bottom:30px;
	}
footer .footer2{
	text-align:center;
	font-size:12px;
	}

footer .direccion{
	border-right:1px solid white;border-left:1px solid white; padding-right:10px;padding-left:10px;
	}
.mapaweb h4{
	font-size: 14px;
}
.mapaweb li a{
	font-size: 12px;
}
.iconos-footer{
	margin-top:20px;
	font-size: 15px;
    margin-right: 20px;
}
.iconos-footer ul li{
	margin-left:20px;
	margin-right:20px;
}
.titulo-seccion{
	padding-bottom: 10px;
    text-align: center;
}




/*************MAPA***************************/
#map_canvas {
    color: #000000;
    height: 400px;
    outline: medium none;
    text-decoration: none;
    width: 100%;
}
#map_canvas img{max-width:none;}
.gm-style-iw{
	font-weight:bold !important;
}
.mapa .evento{
	border-bottom: 1px solid #EAEAEA;
	cursor:pointer;

	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
	}

.mapa .evento:hover, .mapa .evento:active{
	background-color: #F4F4F4;
}
.evento{
    padding: 5px;
}

.no-gutter [class*="col"]  {
	padding-left:0;
    padding-right:0;
}

.row-especial div {
    -webkit-transition: width 0.3s ease, margin 0.3s ease;
    -moz-transition: width 0.3s ease, margin 0.3s ease;
    -o-transition: width 0.3s ease, margin 0.3s ease;
    transition: width 0.3s ease, margin 0.3s ease;
}

.row-especial .col-xs-0 {
    width: 0%;
	float: left;
}
.row-especial .col-xs-0 + [class*="col-xs-"]{
    margin-left: 0;

}

.ratio {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 0;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
}

.evento  .fecha{
	padding-right:10px;
	border-right:1px solid #000000;
	}
.evento .fa{
	font-size:30px;
	}
.evento  .fecha,.evento  .lugar{
	color:#183980;
	}
.evento  .fecha .grande, .evento  .lugar .grande{
	font-size:16px;
	line-height: 10px;
	}
.evento  .fecha .peque, .evento  .lugar .peque{
	font-size:12px;
	}

.subtitulo-evento{  font-style: italic;
  font-weight: 700;}

/******************fin MAPA**************/

.franja{
	padding: 30px 30px 50px;
	text-align:center;
}
.fondo_oscuro{
	background-color:#2D1A0B;
	color:#FFF;
}
.fondo_claro{
	background-color:#F4F4F4;
	color:#2D1A0B;
}
.fondo_blanco{
	background-color:#ffffff;
	color:#2D1A0B;
}

.franja .ano{
	font-size:50px;
}
.hr-corta{
	margin-left: 25%;
    margin-right: 25%;
}
.franja .texto-destacado{
	padding-top:10px;
	font-size:18px;
}
.linea_oscura{
	display: block;
	height: 1px;
    border: 0;
	border-top: 1px solid #2D1A0B;
    padding: 0;
}
.btn-marron {
    background-color: #2D1A0B;
    border-color: #2D1A0B;
    color: #FFFFFF;
	-webkit-transition: width 0.3s ease, margin 0.3s ease;
    -moz-transition: width 0.3s ease, margin 0.3s ease;
    -o-transition: width 0.3s ease, margin 0.3s ease;
    transition: width 0.3s ease, margin 0.3s ease;
}
.btn-marron:hover {
    background-color: #ffffff;
    border-color: #2D1A0B;
    color: #2D1A0B;
}

.btn-azul {
    background-color: #183980;
    border-color: #183980;
    color: #FFFFFF;
	-webkit-transition: width 0.3s ease, margin 0.3s ease;
    -moz-transition: width 0.3s ease, margin 0.3s ease;
    -o-transition: width 0.3s ease, margin 0.3s ease;
    transition: width 0.3s ease, margin 0.3s ease;
}
.btn-azul:hover {
    background-color: #ffffff;
    border-color: #2D1A0B;
    color: #2D1A0B;
}
.btn-azul-borde {
    background-color: #183980;
    border-color: #ffffff;
    color: #FFFFFF;
	-webkit-transition: width 0.3s ease, margin 0.3s ease;
    -moz-transition: width 0.3s ease, margin 0.3s ease;
    -o-transition: width 0.3s ease, margin 0.3s ease;
    transition: width 0.3s ease, margin 0.3s ease;
	margin-bottom: 5px;
}
.btn-azul-borde:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #2D1A0B;
}

/*tienda*/



.thumbnails-productos li {
    height: 270px;
}

.cuadro_intro_hover{
    	padding: 0px;
		position: relative;
		overflow: hidden;
		height: 250px;
		background-color:#ffffff;
		text-align: center;
	}
.cuadro_intro_hover:hover .caption{
		opacity: 1;
		transform: translateY(-200px);
		-webkit-transform:translateY(-200px);
		-moz-transform:translateY(-200px);
		-ms-transform:translateY(-200px);
		-o-transform:translateY(-200px);
	}
.cuadro_intro_hover img{
		z-index: 4;
	}
.cuadro_intro_hover .caption{
		position: absolute;
		top:200px;
		-webkit-transition:all 0.5s ease-in-out;
		-moz-transition:all 0.5s ease-in-out;
		-o-transition:all 0.5s ease-in-out;
		-ms-transition:all 0.5s ease-in-out;
		transition:all 0.5s ease-in-out;
		width: 100%;
	}
.cuadro_intro_hover .blur{
		background-color: #ffffff;
		-moz-opacity:0.5;opacity:0.5;filter: alpha(opacity=50);
		height: 300px;
		z-index: 5;
		position: absolute;
		width: 100%;
	}
.cuadro_intro_hover .caption-text{
		z-index: 10;
		color: #000000;
		position: absolute;
		height: 300px;
		text-align: center;
		top:-20px;
		width: 100%;
	}
.caption-text h3{
		/*border-top: 1px solid #CCCCCC;*/
		padding:10px;
		line-height: 32px; /**********PARA SI HAY 2 LINEAS EN EL H3 QUE SOLO SE VEA LA PRIMERA*****/
	}
.caption-text p{
		padding-left:10px;
		padding-right: 10px;
	}
.cuadro_intro_hover img{
	display: inline;
	}



/****************************************************************************************************/
 .titulo-linea{
	border-bottom:1px solid #cdcdcd;
	margin-top:40px;
	padding-bottom:5px;
	font-weight: 600;
	letter-spacing: .5px;
	  padding: 0 0 10px 0
 }

  .titulo-linea span{
	float: right;
    font-size: 14px;
    padding-top: 5px;
    text-align: right;
 }


 .noticia-enlace, .noticia-portada{
	margin-top:20px;
	border-radius:3px;
	text-align: justify;
	/*max-height: 250px;*/
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
	border: 1px solid #E8E8E8;
	border-radius: 3px;
	box-shadow: 0 2px 0 #E8E8E8;
 }
  .noticia-enlace h4, .noticia-portada h4 {
	font-size:16px;
	padding-left:20px;
	padding-right:20px;
	color: #183980;
	font-weight: 700;
 }
 .noticia-enlace p, .noticia-portada p{
	padding-left:20px;
	padding-right:20px;
	color: #666666;
 }
 .noticia-enlace:hover {
	background-color: #183980;
 }
 .noticia-enlace:hover  > p, .noticia-enlace:hover  > h4{
	color: #ffffff;
 }



  .recuadro-enlace{
	margin-top:20px;
	border-radius:3px;
	font-size: 12px;
	border: 1px solid #E8E8E8;
	border-radius: 3px;
	box-shadow: 0 2px 0 #E8E8E8;
 }
  .recuadro-enlace h4{
	font-size:16px;
	padding-left:20px;
	padding-right:20px;
	color: #183980;
	font-weight: 700;
 }
 .recuadro-enlace p{
	padding-left:20px;
	padding-right:20px;
	color: #666666;
 }
 .recuadro-enlace:hover {
	background-color: #183980;
 }
 .recuadro-enlace:hover  > p, .recuadro-enlace:hover  > h4{
	color: #ffffff;
 }




 .fondo-color{
background-color: #f5f5f5;
    padding-bottom: 50px;
    padding-top: 20px;
	margin-top: 20px;
 }
 .lista-ciudadano {
	line-height:10px;
	margin-bottom:20px;
 }
 .lista-ciudadano .ciudadano {
	color: #183980;
	font-size:18px;
	font-weight: 700;
 }
 .lista-ciudadano  .tema{
	font-size:16px;
	font-style: italic;
	font-weight: 600;
 }
 .lista-ciudadano .texto{
	font-size:14px;
 }

/*******PROYECTOS**********/
.franja-seccion{
	background-color: #f6f6f6;
}
.franja-seccion .titulo-seccion{

	float:left;
	padding-bottom:0px;
	}
.franja-seccion .titulo-seccion h2{
	margin-bottom: 15px;
    margin-top: 10px;
	font-size:20px;

}
.franja-seccion .separador-seccion{
	border-right:5px solid #ffffff;
	height:100%;
	float:left;
	height: 46px;
	padding-right:30px;
    margin-right: 20px;
	}
.franja-seccion .enlaces{
	margin-top: 14px;
	font-size:14px;
}
.franja-seccion .enlaces a{
    padding-bottom: 10px;
    margin-right: 20px;
}
.franja-seccion .enlaces a:hover, .franja-seccion .enlaces a.activo{
    border-bottom: 1px solid #183980;
}


.bootstrap-select.form-control{
    margin-bottom: 10px;
}
.pagination > li > a, .pagination > li > span {
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    color: #183980;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #183980;
    border-color: #183980;
    color: #fff;
    cursor: default;
    z-index: 2;
}
.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
    background-color: transparent;
    border-color: transparent;
    color: #2a6496;
}

.subtitular-azul{color:#183980;}

/***********plataforma.php************/
 .recuadro-normal{
	margin-top:20px;
	border-radius:3px;
	text-align: center;
	font-size: 14px;
}
.recuadro-normal h4{
	font-size:17px;
	padding-left:20px;
	padding-right:20px;
	color: #183980;
	font-weight: 700;
 }
.recuadro-normal p{
	text-align: justify;
	padding-left:20px;
	padding-right:20px;
 }
.fondo-azul{
	background-color:#183980;
	color:#ffffff;
	padding-bottom:10px;
	}
.fondo-azul a{
	color:#ffffff;
	}
.texto-cta{
	text-align:center;
	font-size:16px;
	}
.fondo-mano-piedra{
	background:transparent;
	}
@media (min-width: 1200px) {
	.fondo-mano-piedra{
		background:url(../images/manopiedra-lateral.jpg) no-repeat top right;
		}
	}
/********************proyecto.php***********/
.iconos-sociales{
	color: #ffffff;
	}
.list-inline.iconos-sociales > li {
    padding-left: 0;
    padding-right: 0;
}

.fila-subtitulo{
	  font-weight: 7	00;
  color: #183980;
}
/***************agentes.php****************/
.fila-agente {
	border-bottom:1px solid #cdcdcd;
	padding-top:10px;padding-bottom:10px;
	margin-top:10px;margin-bottom:10px;
	}
.fila-agente .direccion-agente h4{
	margin-top: 0;
	font-weight: 600;

	}
.fila-agente .direccion-agente{
	border-right:1px solid #cdcdcd;
	font-size: 13px;
	line-height: 1.75em;
	}
.fila-agente .imagen-agente a:hover > img{
	border: 2px solid #ffffff;
    opacity: 0.6;
	}

/********************agente.php******************/

.datos-agente .dl-horizontal dt {
    font-weight: lighter;
    text-align: left;
}
.datos-agente .dl-horizontal dd {
    font-weight: bold;
}
.mini-galeria .col-xs-6{
	margin-bottom:10px;
	}
.actividades-recientes {
	border-bottom:1px solid #cdcdcd;
	padding:10px;
	}
.actividades-recientes h4 span{
	font-style:italic;
	color:#222222;
	font-size:12px;
	}
.actividades-recientes:hover{
	background-color: #F4F4F4;
	}
.actividades-recientes h4, .actividades-recientes .evento{
	color:#183980;
		font-weight: 600;

	}
.actividades-recientes .evento{
	padding-top:10px;
	}


/********************mediateca.php*************************/
 .recuadro-icono-enlace{
	border-bottom: 1px solid #183980;
    margin-top: 20px;
    text-align: center;
 }
  .recuadro-icono-enlace h4{
	font-size:17px;
	padding:10px;
	color: #183980;
	margin-bottom: 0;
	-webkit-transition:all 0.3s ease-in-out;
		-moz-transition:all 0.3s ease-in-out;
		-o-transition:all 0.3s ease-in-out;
		-ms-transition:all 0.3s ease-in-out;
		transition:all 0.3s ease-in-out;
 }

 .recuadro-icono-enlace:hover >h4 {
	background-color: #183980;
	color:#ffffff;
 }

.margenes{padding: 20px;}

/**********************listado-php**********************/
.listado li{
	border-bottom:1px solid #cdcdcd;
	}
.listado li{
	border-bottom:1px solid #cdcdcd;
	}
.listado li{
	border-bottom:1px solid #cdcdcd;
	}

/*******************detalle-empleo.php******************/
.detalle-empleo .resumen{
	 background-color: #f5f5f5;
    padding: 20px;
	}
.detalle-empleo .resumen span{
	color:#183980;
	}
/*******************actividad.php******************/
.detalle-actividad .descripcion, .detalle-actividad .resumen{
	 background-color: #f5f5f5;
    padding: 20px;
	}
.detalle-actividad .resumen span{
	color:#183980;
	}
